var generic = generic || {};
var site = site || {};

(function ($) {
  site.signupFooter = {
    templateContainer: $(),

    initSignUpFooter: function () {
      var $emailContainerNodes = this.templateContainer;

      if (!$emailContainerNodes.length) {
        return null;
      }
      $emailContainerNodes.each(function () {
        var $signUpContainerNode = $(this);
        var $signUpForm = $signUpContainerNode.is('form')
          ? $signUpContainerNode
          : $('form', $signUpContainerNode);
        var $signUpFields = $('.signup-footer__fields', $signUpContainerNode);
        var $signUpSuccess = $('.js-signup-footer__success', $signUpContainerNode);
        var $emailSuccessMessage = $('.signup-footer__success-message', $signUpContainerNode);
        var $offerSuccessMessage = $('.signup-footer__success-offer', $signUpContainerNode);
        var $emailError = $('.js-signup-footer__error', $signUpContainerNode);
        var $emailInput = $('input[name="PC_EMAIL_ADDRESS"]', $signUpContainerNode);
        var $languageIDInput = $('input[name="LANGUAGE_ID"]', $signUpContainerNode);
        var $emailTermsLabel = $('.signup-footer__terms-conditions-label', $signUpContainerNode);
        var showEmailPromo = $signUpContainerNode.hasClass('show-email-promotion-option');
        var $mobileNumberInput = $('input[name="MOBILE_PHONE"]', $signUpContainerNode);
        var $mobileTermsLabel = $(
          '.signup-footer__terms-conditions-sms-label',
          $signUpContainerNode
        );
        var hideSMSPromo = $signUpContainerNode.hasClass('no-sms-promotion');

        if (Drupal.settings.common.has_onelink_trans) {
          if (generic.cookie('LOCALE') === Drupal.settings.common.locate_one_link_translation) {
            $languageIDInput.val(Drupal.settings.common.lang_id_one_link_translation);
          }
        }

        $signUpForm.once('js-signup-footer').submit(function (submitEvt) {
          submitEvt.preventDefault();
          var params = {};

          $signUpSuccess.addClass('hidden');
          $emailError.html('');
          $emailInput.removeClass('error');

          if (!hideSMSPromo) {
            $mobileTermsLabel.removeClass('error');
            $mobileNumberInput.removeClass('error');
          }
          if (showEmailPromo) {
            $emailTermsLabel.removeClass('error');
          }

          // Transform string into array of form elements
          $.each($signUpForm.serializeArray(), function (index, kv) {
            if (kv.name === 'MOBILE_PHONE') {
              kv.value = kv.value.replace(/[^0-9]+/g, '');
            }
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });

          // handle the sms_promotions checkbox - if its checked its being read as 'on' when serialized
          // we need a boolean value
          if (!hideSMSPromo) {
            if ($signUpContainerNode.find('input[name="SMS_PROMOTIONS"]').prop('checked')) {
              params.SMS_PROMOTIONS = 1;
            }
          }
          if (showEmailPromo) {
            if ($signUpContainerNode.find('input[name="PC_EMAIL_PROMOTIONS"]').prop('checked')) {
              params.PC_EMAIL_PROMOTIONS = 1;
            } else {
              // If $emailInput has a value and we show email checkbox option,
              // it's mandatory, so if it's not checked, we return.
              if ($emailInput.val() !== '') {
                return;
              } else {
                params.PC_EMAIL_PROMOTIONS = 0;
              }

            }
          }

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function () {
              $signUpFields.addClass('hidden');
              $signUpSuccess.html($emailSuccessMessage.val() + '<br>' + $offerSuccessMessage.val());
              $signUpSuccess.removeClass('hidden');
            },
            onFailure: function (jsonRpcResponse) {
              // Get errors back and build a set of list items to inject into the HTML.
              var messages = jsonRpcResponse.getMessages();
              var errorHTML = '';
              var errorOrder = [
                'required.pc_email_address.email_signup',
                'invalid.pc_email_address.email_signup',
                'required_dependency.pc_email_address.pc_email_promotions.dependency.email_signup',
                'required_dependency.pc_email_promotions.pc_email_address..dependency.email_signup',
                'format.mobile_phone.sms',
                'invalid.mobile_phone.sms',
                'required_dependency.mobile_phone.sms_promotions.dependency.sms',
                'required_dependency.sms_promotions.mobile_phone.dependency.sms'
              ];

              $emailError.empty();
              $signUpContainerNode.find('input').removeClass('error');

              messages = jQuery.grep(messages, function (a) {
                return a.key !== '' && a.key !== 'system.error';
              });

              messages.sort(function (a, b) {
                return errorOrder.indexOf(a.key) - errorOrder.indexOf(b.key);
              });

              for (var i = 0; i < messages.length; i++) {
                var myErr = messages[i];

                if (myErr && myErr.key) {
                  // Use the jsonRpcResponse tags to pair with input nodes.
                  // Error tags are returned with details of which form, field are tagged to that particular error message
                  var fieldName = myErr.tags[2].substr(6).toUpperCase();
                  var $node = $('[name=' + fieldName + ']', $signUpForm);

                  if ($node.length && !$node.hasClass('error')) {
                    errorHTML += '<li class="' + myErr.key + '">' + myErr.text + '</li>';
                    $node.addClass('error');
                  }

                  if (
                    myErr.key ===
                      'required_dependency.pc_email_address.pc_email_promotions.dependency.email_signup' ||
                    myErr.key ===
                      'required_dependency.pc_email_promotions.pc_email_address..dependency.email_signup'
                  ) {
                    $emailInput.addClass('error');
                    $emailTermsLabel.addClass('error');
                  }
                  if (
                    myErr.key ===
                      'required_dependency.mobile_phone.sms_promotions.dependency.sms' ||
                    myErr.key === 'required_dependency.sms_promotions.mobile_phone.dependency.sms'
                  ) {
                    $mobileNumberInput.addClass('error');
                    $mobileTermsLabel.addClass('error');
                  }
                }
              }
              $emailError.html(errorHTML).removeClass('hidden');
              generic.focusErrors($('.js-signup-footer__error'), $('.js-email-signup-processed'));
            }
          });
        });
      });
    }
  };

  Drupal.behaviors.signupFooterV1 = {
    attach: function (context) {
      site.signupFooter.content = $('.site-footer-pc__column', context);
      site.signupFooter.templateContainer = $('.js-signup-footer', context);
      site.signupFooter.emailSubmit = $(
        '.js-signup-footer__submit',
        site.signupFooter.templateContainer
      );
      site.signupFooter.formInputs = $(
        'input[name="PC_EMAIL_ADDRESS"], input[name="MOBILE_PHONE"]',
        site.signupFooter.templateContainer
      );
      site.signupFooter.emailAddress = $(
        'input[name="PC_EMAIL_ADDRESS"]',
        site.signupFooter.templateContainer
      );
      site.signupFooter.mobileNumberInput = $(
        'input[name="MOBILE_PHONE"]',
        site.signupFooter.templateContainer
      );
      site.signupFooter.smsTermsAndConditions = $(
        '.js-signup-footer__sms-terms-conditions',
        site.signupFooter.templateContainer
      );
      site.signupFooter.emailTermsAndConditions = $(
        '.js-signup-footer__email-terms-conditions',
        site.signupFooter.templateContainer
      );
      site.signupFooter.initSignUpFooter();

      // Show email sign up copy
      site.signupFooter.emailAddress.off('focus').on('focus', function () {
        site.signupFooter.emailTermsAndConditions.slideDown(300, function () {
          Drupal.behaviors.grids.equalHeights(site.signupFooter.content);
        });
        site.signupFooter.smsTermsAndConditions.slideUp(300, function () {
          Drupal.behaviors.grids.equalHeights(site.signupFooter.content);
        });
      });

      // Show terms + conditions
      site.signupFooter.mobileNumberInput.off('focus').on('focus', function () {
        site.signupFooter.smsTermsAndConditions.slideDown(300, function () {
          Drupal.behaviors.grids.equalHeights(site.signupFooter.content);
        });
      });

      // hide terms + conditions when clicked outside
      $(document).click(function (hideEvt) {
        if (
          !$(hideEvt.target).closest(site.signupFooter.templateContainer).length &&
          !$(hideEvt.target).is(site.signupFooter.templateContainer)
        ) {
          if (site.signupFooter.emailTermsAndConditions.is(':visible')) {
            site.signupFooter.emailTermsAndConditions.slideUp(300, function () {
              Drupal.behaviors.grids.equalHeights(site.signupFooter.content);
            });
          }
          if (site.signupFooter.smsTermsAndConditions.is(':visible')) {
            site.signupFooter.smsTermsAndConditions.slideUp(300, function () {
              Drupal.behaviors.grids.equalHeights(site.signupFooter.content);
            });
          }
        }
      });

      site.signupFooter.formInputs.each(function () {
        $(this).on('focus', function () {
          site.signupFooter.emailSubmit.removeClass('hidden');
        });
      });
    }
  };
})(jQuery);
